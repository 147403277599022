const headers = document.querySelectorAll("[data-name='accordeon-title']");

headers.forEach(function (item) {
  item.addEventListener('click', function () {

    this.nextElementSibling.classList.toggle('is-hidden');
    this.querySelector('.list-header-title').classList.toggle('list-header-title-active');
    this.querySelector('.list-header-img').classList.toggle('list-header-img-active');
  })
});
